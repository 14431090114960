import React from "react";
import { ShieldCheck } from "tabler-icons-react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";

import { Flex, Text, useTheme } from "../styled";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Impressum" keywords={[`gatsby`, `application`, `react`]} />
      <Wrapper maxWidth={800} py="5rem">
        <Flex>
          <Text font="h3" color="secondary">
            Impressum
          </Text>
          <Text font="h5" py={15} color="secondary">
            Kontaktdaten
          </Text>
          <Text>
            WKM Rösner GmbH
            <br />
            Im Weiherfeld 12
            <br />
            41334 Nettetal
            <br />
            <br />
            Amtsgericht Krefeld
            <br />
            HRB 18980
            <br />
            StNr.: 115/5751/0913
            <br />
            <br />
            Geschäftsführer
            <br />
            Friederike Rösner
            <br />
            Christoph Rösner
            <br />
            <br />
            Telefon: 02153 97230-5
            <br />
            Fax: 02153 97230-6
            <br />
            Mobil: 0160 97775347
            <br />
            E-Mail:
            <a href="mailto:roesner@wkmonline.de">roesner@wkmonline.de</a>
            <br />
          </Text>
          <Text font="h5" py={15} color="secondary">
            Inhaber
          </Text>
          <Text>
            Friederike Rösner
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a<br />
            Umsatzsteuergesetz: DE231754809
            <br />
            <br />
            Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:
            <br />
            Friederike Rösner
          </Text>
          <Text font="h5" py={15} color="secondary">
            Haftung für Inhalte
          </Text>
          <Text mb={15}>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </Text>
          <Text font="h5" py={15} color="secondary">
            Haftung für Links
          </Text>
          <Text mb={15}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </Text>
          <Text font="h5" py={15} color="secondary">
            Urheberrechte
          </Text>
          <Text mb={15}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </Text>
          <Text font="h5" py={15} color="secondary">
            Design und technische Realisierung
          </Text>
          <Text mb={15}>
            Design: <a href="www.punktzwei.de">punktzwei Markenagentur</a>
            <br />
            Technische Realisierung:
            <a href="www.kations.de">Kations digitale Kommunikation</a>
          </Text>
        </Flex>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
